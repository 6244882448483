<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title row w-100">
                    <div class="d-flex p-0 align-items-center position-relative mb-4">
                        <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    </div>
                    <div class="d-flex flex-column p-0 position-relative mb-4" v-if="!loadingTable">
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Kode Return</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${route.params.kode_return}` }}</span></div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Tanggal</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${moment(tempData.tanggal).format('DD-MM-YYYY HH:mm')}` }}</span></div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Jenis</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6 text-uppercase">: 
                                <span class="ms-2">{{ `${tempData.jenis}` }}</span>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Status</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.status}` }}</span></div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-2 text-gray-500 fw-bolder me-6 fs-6">Keterangan</div>
                            <div class="col text-gray-500 fw-bolder me-6 fs-6">: <span class="ms-2">{{ `${tempData.description}` }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content sticky">
            <div v-if="tempData.jenis == 'item'"  class="table-fixed-header">
                <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th class="w-150px">Brand</th>
                            <th class="w-200px">SKU</th>
                            <th class="w-150px">Artikel</th>
                            <th>Kategori</th>
                            <th class="w-100px">Warna</th>
                            <th class="w-100px">Ukuran</th>
                            <th class="w-100px">Status</th>
                            <th class="text-end w-150px">Harga</th>
                            <th class="text-end w-100px">Dikirim</th>
                            <th class="text-end w-100px" v-if="tempData.status != 'PENDING'">Diterima</th>
                            <th class="text-end w-150px">Total</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Action</th>                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr class="cursor-pointer" v-for="(item, i) in tableData" :key="i" @click.stop="tempData.status == 'DIKIRIM' ? (tableData[item.index-1].status = !item.status, item.error = false) : null" :style="{ 'background': item.error ? 'red' : ''  }">
                            <td class="w-50px">{{ i+1 }}</td>
                            <td class="w-150px">{{ item.brand_name }}</td>
                            <td class="w-200px">{{ item.sku }}</td>    
                            <td class="w-150px">{{ item.artikel }}</td>
                            <td class="w-150px">{{ item.raw_category }}</td>
                            <td class="w-100px">{{ item.color }}</td>
                            <td class="w-100px">{{ item.size }}</td>
                            <td class="w-100px text-capitalize">{{ item.product_status }}</td>
                            <td class="text-end w-150px">{{ formatIDR(item.price) }}</td>
                            <td class="text-end w-100px">
                                <span class="text-end">{{ item.qty_sent }}</span>
                            </td>
                            <td class="text-end w-100px" v-if="tempData.status != 'PENDING'" @click.stop>
                                <input class="qty" v-if="tempData.status == 'DIKIRIM'" v-model="tableData[item.index-1].qty_received" :id="'qtyReceived_'+item.index" @change="item.qty_sent == item.qty_received ? tableData[item.index-1].status = true : null"/>
                                <span v-else>{{ item.qty_received }}</span>
                            </td>
                            <td class="text-end w-150px">{{ item.total }}</td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <input v-if="tempData.status == 'DIKIRIM'" class="form-check-input" :class="{'text-success': item.qty_sent == item.qty_received, 'text-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" type="checkbox" :value="tableData[item.index-1].status" :checked="item.status" @click.stop="tableData[item.index-1].status = !item.status, item.error = false"/>
                                    <input v-else type="checkbox" class="form-check-input" :class="{'text-success': item.qty_sent == item.qty_received, 'text-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" checked disabled :value="true" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-start fw-boldest fs-6 gs-0" v-if="tableData.length > 0">
                            <td colspan="8">Total</td>
                            <td class="text-end w-150px">{{ tableData.map(o => o.qty_sent).reduce((p,n) => p + n, 0) }}</td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ tableData.map(o => o.qty_received).reduce((p,n) => parseInt(p) + parseInt(n), 0) }}</td>
                            <td class="text-end w-150px">{{ formatIDR(tableData.map(o => parseInt(o.price * o.qty_received)).reduce((p,n) => parseInt(p) + parseInt(n) ,0)) }}</td>
                            <td v-if="tempData.status != 'PENDING'"></td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div v-else class="table-fixed-header">
                <table class="table table-rounded table-row-dashed" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-100px">#</th>
                            <th>Gambar</th>
                            <th>Desain ID</th>
                            <th>Folder</th>
                            <th>Ukuran Print</th>
                            <th class="text-end w-150px">Jumlah Dikirim</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Jumlah Diterima</th>
                            <th class="text-end w-150px" v-if="tempData.status != 'PENDING'">Status</th>
                            <th class="text-end" v-if="tempData.status == 'PENDING'">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i" @click="tempData.status == 'DIKIRIM' ? (tableData[item.index-1].status = !item.status, item.error = false) : null">
                            <td class="w-100px">{{ i+1 }}</td>
                            <td>
                                <img class="tbl-image" @click="viewImage(item)" :src="img_url_desain+item.gambar">
                            </td>
                            <td>{{ item.desain_id }}</td>
                            <td>{{ item.folder }}</td>
                            <td>{{ item.print_size }}</td>    
                            <td class="text-end w-150px">
                                <span class="text-end">{{ item.qty_sent }}</span>
                            </td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ item.qty_received }}</td>
                            <td class="text-end" v-if="tempData.status == 'GAGAL'">
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <i class="fas fa-times text-danger"></i>
                                </div>
                            </td>
                            <td class="text-end" v-if="tempData.status != 'PENDING' && tempData.status != 'GAGAL'" @click.stop> 
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <input class="form-check-input" :class="{'bg-success': item.qty_sent == item.qty_received, 'bg-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" type="checkbox" :value="true" disabled checked="checked" @change="item.qty_sent == item.qty_received ? tableData[item.index-1].status = true : null">
                                </div>
                            </td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">
                                <div class="form-check form-check-custom form-check-solid justify-content-end">
                                    <input v-if="tempData.status == 'DIKIRIM'" class="form-check-input" :class="{'text-success': item.qty_sent == item.qty_received, 'text-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" type="checkbox" :value="tableData[item.index-1].status" :checked="item.status" @click.stop="tableData[item.index-1].status = !item.status, item.error = false" :disabled="!item.status" />
                                    <input v-else type="checkbox" class="form-check-input" :class="{'text-success': item.qty_sent == item.qty_received, 'text-warning': item.qty_sent > item.qty_received || item.qty_sent < item.qty_received}" checked disabled :value="true" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-start fw-boldest fs-6 gs-0" v-if="tableData.length > 0">
                            <td colspan="5">Total</td>
                            <td class="text-end w-150px">{{ tableData.map(o => parseInt(o.qty_sent)).reduce((p,n) => parseInt(p) + parseInt(n), 0) }}</td>
                            <td class="text-end w-150px" v-if="tempData.status != 'PENDING'">{{ tableData.map(o => parseInt(o.qty_received)).reduce((p,n) => parseInt(p) + parseInt(n), 0) }}</td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>

            <div class="row m-0 justify-content-center" v-if="tempData.status.toUpperCase() == 'DIKIRIM'">
                  <button
                      type="submit"
                      ref="submitButton"
                      class="btn btn-primary w-300px mt-7"
                      :disabled="loadingSubmit || tableData.some(o => o.status == false) || tempData.status.toUpperCase() != 'DIKIRIM'"
                      :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                      @click="confirmUpdate()"
                  >
                      <span class="indicator-label"> Konfirmasi </span>

                      <span class="indicator-progress">
                          Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                  </button>
            </div>
        </div>
        
        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
            <inner-image-zoom :src="getZoomImage.src"/>
        </el-dialog>
    </div>
</template>

<style scoped lang="scss">
    input.qty {
        width: 60px;
        padding: 6px;
        text-align: center;
        border: solid 1px #999;
    }
    input.qty:focus {
        border: solid 1px #999;
        outline: none;
    }
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute, useRouter } from 'vue-router'
import InnerImageZoom from 'vue-inner-image-zoom';
import { ElMessageBox } from 'element-plus'
import moment from 'moment'


export default defineComponent({
    components: {
        InnerImageZoom,
    },
    setup() {
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)

        const [route, router] = [useRoute(), useRouter()]

        const loadingTable = ref(true)
        const loadingButton = ref(false)
        const loadingSubmit = ref(false)
        const disabledButton = ref(true)
        const loadingPopover = ref(false)

        const modalVisible = ref(false)
        const modalTitle = ref('')

        const tableData = reactive([])
        const totalRecordCount = ref(0)
        
        const tableHeader = reactive([]);

        const tempData = reactive({
            kode_return: '',
            jenis: '',
            description: '',
            pengirim: '',
            penerima: '',
            tanggal: '',
            status: '',
        })

        const data = reactive({
            status: false,
        })

        const filterActive = ref(false)
        const filter = reactive({
            kode_return: null,
        })
                
        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            filterActive.value = false

            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {   
            loadingTable.value = true

            filter.desain_id = null
            filter.folder_id = null

            filterActive.value = false

            await getData()

            loadingTable.value = true
        }
         
        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.desain_id
            getZoomImage.src = img_url_desain.value+data.gambar
        }

        const getData = async(page) => {
            try {
                await tableData.splice(0)
                loadingTable.value = true

                let search = filter.search ? '&search='+filter.search : '';
                        
                await ApiService.get("return/list/"+route.params.kode_return + search)
                .then(async({ data }) => {
                    Object.assign(tableData, data.data.data)

                    tempData.kode_return = data.data.parent.kode_return
                    tempData.description = data.data.parent.description
                    tempData.jenis = data.data.parent.jenis
                    tempData.tanggal = data.data.parent.tanggal
                    tempData.pengirim = data.data.parent.pengirim
                    tempData.penerima = data.data.parent.penerima
                    tempData.status = data.data.parent.status

                    tableData.map((o, i) => {
                        o.index = i+1;
                      if(o.jenis_vendor.toUpperCase() == 'KONSINYASI') {
                          o.total = formatIDR(parseInt(o.qty_received) * parseInt(o.price))
                      } else {
                          o.total = formatIDR(parseInt(o.qty_sent) * parseInt(o.price))
                      }

                      if(o.checklist == 'SUDAH') {
                          o.status = true;
                      }
                      else {
                          o.status = false;
                      }
                    })
                })
                .catch(({ response }) => {
                    console.log('Error getting return detail');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const confirmUpdate = async() => {
          try {
            ElMessageBox({
                title: 'Konfirmasi',
                message: 'Konfirmasi update return barang?',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
            })
            .then(() => {
              onSubmit()
            })
            .catch((e) => {
                throw new Error(e)
            })

          } catch(e) {
            console.error(e)
            Swal.fire({
                title: e.message,
                icon: "error",
                showConfirmButton: false,
                timer: 3000
            });
          }
        }

        const onSubmit = async () => {
            try {
                loadingSubmit.value = true;

                tableData.map(o => o.checklist = o.status ? 'SUDAH' : 'BELUM')

                await ApiService.post('return/confirm_return', { data: tableData, kode_return: tempData.kode_return, jenis: tempData.jenis })
                .then((response) => {
                    if(response.data.error) {
                        throw new Error(response.data.messages)
                    }

                    Swal.fire({
                        title: response.data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch((error) => {
                    // Highlight error
                    if(error.response.data.data) {
                        let getRawError = tableData.find(o => o.raw_product_id == error.response.data.data)
                        if(getRawError) {
                            tableData[getRawError.index-1].error = true
                            tableData[getRawError.index-1].status = false
                        }
                        console.log(getRawError)
                    }
                    throw new Error(error.response.data.messages)
                })

                getData();

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }

            loadingSubmit.value = false
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Detail Return", ["return", route.params.kode_return]);

            getData()
        })

        return {
            moment,
            route, router,
            formatIDR,
            img_url_desain, 
            loadingTable, loadingSubmit, disabledButton, loadingButton,
            modalVisible, modalTitle, 
            filter, filterActive, onFilter, resetFilter,
            getZoomImage, viewImage,
            tableHeader, tableData, totalRecordCount, tempData,
            confirmUpdate,
        }
    }
})
</script>